import React from "react"

export default (props) => (
  <svg
    className={ `site-logo ${props.class}` }
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 541.68 93.05"
    style={{enableBackground: 'new 0 0 541.68 93.05'}}
    xmlSpace="preserve"
  >
    <g>
      <path d="M94.23,15.49c-2.97,5.53-8.63,8.35-12.39,13.23c-5.55,5.86-9.28,12.95-13.35,19.79c-5.09,6.99-11.01,21.56-21.77,16.66
		c-5.34-2.23-7.29-6.81-7.79-11.94c-0.86-3.2-4.36-23.83-7.28-22.72c-4.98,12.41-11.89,23.92-19.45,34.92
		c-2.18,3.18-6.79-0.4-9.89-0.48c-2.44-0.65-2.96-2.11-1.45-4C12.61,45.48,20.99,26.49,26.58,8.25c-0.3-5.09,8.56-0.85,11.53-2.86
		c1.71,1.49,3.61,1.42,2.3,3.86c-1.13,2.87-0.38,5.51,0.38,8.18c3.98,12.45,5.9,25.62,11.09,37.62
		c8.88-11.98,15.93-25.17,24.95-37.12c2.8-4.32,8.26-2.75,12.57-3.11c1.5,0.21,3.13-0.96,3.64,1.26C99,4.65,92.03,5.25,109.03,7.19
		c0.15,4.05-4.14,6.78-4.81,10.9c-1.26,1.94-1.19,5.97-3.66,6.52c-4.59-0.11-7.93-4.59-5.91-8.95
		C94.51,15.61,94.37,15.55,94.23,15.49z" />
      <path d="M494.88,61.98c-3.42,1.88-6.73,0.51-10.55,0.55c-0.64,3.67-1.25,7.13-1.91,10.88c12.59,1.72,25.27,1.54,37.89,3.16
		c5.14,1.76,23.93-2.12,21.07,6.68c-57.33-6.63-118.86-11.41-173.77,7.98c-2.08,0.27-8.22,3.13-9.61,1.08
		c5.6-12.07,45.98-12.93,59.38-16.42c0.45-1.9,0.89-3.77,1.32-5.65c0.12,0.02,0.25,0.04,0.37,0.06c-0.07,1.11-0.13,2.22-0.2,3.34
		c0.14,0.02,0.27,0.03,0.41,0.05c0.37-4.81,3.14-45.99-2.35-44.19c-3.97-0.84-16.96,4-16.28-2.47c4.05-4.4,11.73-1.92,17.16-3.07
		c0-3.46-1.56-6.82-0.38-10.14c0.23-0.41-0.37-1.41-0.66-1.99c-0.59-1.17-0.13-1.6,0.79-1.98c12.21-4.23,7.54,6.79,9.88,14.08
		c3.43,0.41,6.82-0.29,10.25-0.53c3.26-0.76,2.04,2.41,2.46,4.42c-3.1,2.78-7.82,1.17-11.97,2.28c1.5,14.66,3.01,29.33,1.42,44.15
		c12.91-0.18,25.79-1.08,38.71-1.15c3.66-0.94,2.04-6.77,3.13-9.9c-3.86,0.36-9.02,3-12.05-0.33c-0.64-0.73,0.02-2.1,1.19-2.4
		c4.34-1.04,8.22-1.58,12.78-2.28c-0.18-5.74,0.39-4.94,5.49-6.2c4.89-0.36,9.86-1.23,7.54,5.52
		C489.52,58.26,493.75,58.09,494.88,61.98z" />
      <path d="M216.02,62.2c8.32-6.03,19.53-9.05,24.75-18.72c0.57-1.94,2.77-6.37-0.21-7.15c-7.94-3.53-18.47,2.66-23.59-5.03
		c8.59-5.1,18.71-2.92,27.85-0.56c4.8,0.78,9.43,1.17,8.93,7.2c1.75-4.39,2.58-9.11,3.9-13.64c0.72-4.28,6.53-2.5,9.71-3.45
		c6.3-1.62-4.31,19.67-3.64,23.51c7.64-2.69,14.92,2.69,22.25,4.56c2.68,0.99,3.8,4.27,2.59,6.57c-2.63,4.22-8.97-2.45-12.74-2.76
		c-4.45-1.46-9.07-1.87-13.46-3.69c-2.1,5.97-0.92,12.36-4.85,17.61c-14.31-2.14-6.71-11.45-5.8-21.13
		c-2.87,4.25-5.65,8.24-10.35,10.72C233.65,59.71,223.81,71.27,216.02,62.2z" />
      <path d="M330.16,29.54c0-3.05,0-5.64,0-8.41c-11-0.25-21.7-0.39-32.64-0.21c-5,0.13-10.48,1.79-15.19-0.44
		c5.68-7.83,36.68-1.14,47.34-3.94c1.04-4.09-2.81-6.12,2.58-8.19c1.78-0.81,3.6-1.36,5.53-0.42c6.55,2.09,13.68,0.54,20.4,0.9
		c7.37,1.82-3.4,15.88,4.9,16.92c1.71,0.76,5.02,0.24,5.52,2.4c-1.25,6.64-11.8,0.1-16.01-0.23c-11.34-4.63,7.58-16.85-11.71-14.63
		c-0.52,9.66,4.93,23.61-10.59,19.42c-0.12-0.65-0.22-1.18-0.32-1.7c-11.72,1.97-23.96,1.84-35.92,0.35
		c4.53-6.42,13.09-2.85,19.66-3.39C319.26,28.72,325.21,26,330.16,29.54z" />
      <path d="M145.41,55.94c6.95-8.19,14.24-15.58,18.85-25.5c-5.92-1.9-10.83,2.69-15.98-0.92c-4.26,2.66-7.27,15.42-11.3,12.85
		c-2.37-1.32-6.81-3.79-5.35-7.03c2.94-5.31,5.97-11.85,11.5-14.76c3.12-0.74,11.64-2.04,9.55,3.4c7.68,0.02,15.15-0.05,22.8-0.08
		c2.25-0.01,2.68,0.54,2.03,2.75c-4.79,12.64-13.36,23.5-22.62,33.19C151.57,62.39,147.85,58.58,145.41,55.94z" />
      <path d="M495.32,26.76c1.89-1.47,3.68-2.85,5.94-4.6c-14.52-1.37-27.63-0.04-41.91-0.24c-1.25,2.54,1.94,5.14-0.1,6.83
		c-2.77,1.76-7.86,2.07-10.62-0.9c-2.56-3.61-1.25-8.72-1.95-12.93c0.37-4.95,7.62-4.31,11.32-3.7c0.09,1.28,0.17,2.44,0.28,3.92
		c3.28-0.78,14.03,2.15,13.03-3.12c-0.23-3.7-5.29-5.97-3.35-9.75c2.18-0.58,4.61-0.38,6.53-1.82c0.81-0.59,1.7-0.48,2.27,0.45
		c2.69,4.2,5.92,8.93,3.02,14.05c4.83,1.25,46.18-0.27,32.86,9.88C507.48,26.59,499.69,32.51,495.32,26.76z" />
      <path d="M356.24,40.99c-6.48-1.77-26.68,3.65-28.95-0.78c2.96-4.9,16.94-2.3,22.56-3.14c6.41,0.8,14.4-2.37,19.6,2.22
		c-5.11,5.56-10.17,10.77-15.63,15.92c5.96,3.81,12.39,6.37,19,8.74c1.13,0.4,2.3,0.77,2.66,2.21c-6.68,6.73-17.9-3.83-25.07-6.11
		c-1.2-0.62-1.97-0.52-2.97,0.34c-5.94,5.07-12.9,9.18-20.38,11.54c-15.93,2.16,12.8-13.29,14.36-17.15
		c-2.28-1.92-4.56-3.49-7.37-4.08c-0.7-0.15-1.36-0.42-1.25-1.15c1.33-2.76,5.81-2.84,8.6-3.11c2.16,0.66,3.52,2.53,5.52,3.92
		C350.54,47.67,353.67,44.81,356.24,40.99z" />
      <path d="M89.81,41.54c3.36-3.89,45.57-12.34,34.74-0.17c-0.59,0.91-1.42,1.09-2.49,0.95c-5.69-1.09-10.97,0.26-16.77,1
		c4.85,4.73,12.37,5.6,17.69,10.16c5.91,12.37-25.54,13.31-28.2,4.29c5.64-2.45,12.3,1.95,17.16-2.32
		C105.22,50.12,94.45,49.45,89.81,41.54z" />
      <path d="M478.58,40.41c1.47,0.09,2.32,0.14,3.22,0.2c5.01-7.3,14.21,0.37,15.51,6.94c-4.89,1.21-8.19,4.49-9.4-2.16
		c-9.21-2.22-18.72,7.25-26.62,0.72c-3.05-2.64,8.54-6.13,10.35-12.12c-4.81-0.52-7.94,2.59-10.09-3.3
		c7.56-4.51,17.73-2.1,26.26-3.23c3.71-0.1,2.79,2.88,2.64,5.51C484.91,32.35,482.28,36.69,478.58,40.41z" />
      <path d="M307.54,64.36c0.33,5.76-6.55,3.59-10.11,3.08c-1.12-5.21,0.09-11.02-2.71-15.61c-0.36-0.64,0.28-1.6,0.23-2.26
		c-1.88-4.61,8.87-8.63,9.04-2.55c7.05,0.33,22.44-1.58,27.1,3.97c-1.85,7.99-6.94,7.31-8.68,11.09
		C319.01,65.82,312.27,64.75,307.54,64.36z M307.61,62.07c4.74-1.99,11.54-2.47,12.16-8.73c-0.85-4.04-10.71-1.82-14.07-2.01
		C306.34,54.91,306.96,58.37,307.61,62.07z" />
      <path d="M202.97,38.75c-8.47-2.31-18.64,5.59-24.04-3.69c11.61-4.08,24.13-2.95,35.96-0.66c3.34,0.89-0.15,5.15-0.88,7.07
		c-3.69,6.69-17.03,23.42-25,15.44c-1.41-1.52-1.15-3.19,0.61-4.37C194.86,48.93,200.73,44.95,202.97,38.75z" />
      <path d="M383.18,63.34c-1.12-3.4-4.79,0.45-5.82-2.43c-1.24-5.08-10.86-15.34,0.02-14.32c1.91-0.43,3.49-0.2,4.29,1.85
		C405.62,45.03,407.1,60.87,383.18,63.34z M386.09,58.95c6.31-1.93,4.17-7.38-1.95-6.47C384.76,54.53,385.37,56.56,386.09,58.95z" />
      <path d="M366.61,21.51c-1.13-5.9,7.33-3.92,11-4.6c10.43,1.24,22.07-0.38,31.86,2.98c-1.41,2.71-2.06,3.03-4.8,2.89
		C391.89,21.63,379.43,22.61,366.61,21.51z" />
      <path d="M187.65,21.91c29.53-6.1,30.09,12.77,16.07,6.07C197.6,26.62,191.32,28.86,187.65,21.91z" />
      <path d="M375.54,8.95c0.91-8.03,24.97,0.7,25.14,7.22c-2.81,0.7-6.49,1.54-8.88-0.17C387.89,10.96,381.3,10.93,375.54,8.95z" />
      <path d="M313.54,40.51c-3.63,0.37-28.32,4.12-14.53-2.84c7.79-0.78,16-1.88,23.74-0.53c4.79,4.78-6.74,3.43-9.17,3.86
		C313.56,40.83,313.55,40.67,313.54,40.51z" />
      <path d="M399.52,33.31c-7.93,1.52-15.97-0.72-24.04-0.07c-1.71,0.43-1.84-1.54-0.74-2.33c6.39-3.35,14.18-1.34,21.1-1.36
		C398.18,29.69,400.61,30.02,399.52,33.31z" />
      <path d="M321.32,9.37c-5.73,0.93-10.56-2.22-13.59-6.87c-1.84-3.84,7.28-2.23,9.28-1.76C320.76,2.1,321.1,5.74,321.32,9.37z" />
      <path d="M395.67,38.98c-5.36,3.73-13.46,1.48-19.81,2.8c-1.01,0.07-1.77-0.13-1.61-1.39c5.15-3.8,12.48-2.1,18.54-3.2
		C394.03,37.1,395.2,37.42,395.67,38.98z" />
    </g>
  </svg>
)